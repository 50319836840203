<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <logo
          :height="`35px`"
          :full="true"
        />
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            :alt="$t('Imagem')"
          />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Pedir nova password?') }}
          </b-card-title>

          <validation-observer
            ref="forgotPasswordForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-forgot-password-form mt-2"
              :autocomplete="`form-auth-${Date.now()}`"
              @submit.prevent="forgotPassword"
            >
              <b-form-group
                :label="$t('Email')"
                :label-for="`user-email-${formID}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  rules="required|email"
                >
                  <b-form-input
                    :id="`user-email-${formID}`"
                    v-model="userEmail"
                    :name="`user-email-${formID}`"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    inputmode="email"
                    autocomplete="off"
                    :tabindex="1"
                    :readonly="emailReadOnly"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="listCountryUser.length"
                :label-for="`user-country-${formID}`"
                label="País"
              >
                <validation-provider
                  #default="{ errors }"
                  name="país"
                  rules="required"
                >
                  <v-select
                    :id="`user-country-${formID}`"
                    v-model="userCountry"
                    :name="`user-country-${formID}`"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listCountryUser"
                    :state="errors.length > 0 ? false:null"
                    label="text"
                    :tabindex="2"
                  >
                    <template #option="{ text, icon }">
                      <feather-icon
                        :icon="icon"
                        size="16"
                        class="align-middle mr-50"
                      />
                      <span> {{ text }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loadingSubmit"
                :tabindex="3"
              >
                <b-spinner
                  v-if="loadingSubmit===true"
                  small
                />
                <span v-if="loadingSubmit===true">&nbsp;{{ $t('Aguarde...') }}.</span>
                <span v-if="loadingSubmit===false">&nbsp;{{ $t('Recuperar password') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('Voltar') }}
            </b-link>
          </p>
        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import logo from '@core-custom/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BImg, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { VeeValidateLoadLocale } from '@core-custom/mixins/geral'

export default {
  components: {
    logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BCardTitle,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [VeeValidateLoadLocale],
  data() {
    return {
      userEmail: null,
      userCountry: null,
      required,
      email,
      listCountryUser: [],
      loadingSubmit: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      emailReadOnly: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    formID() {
      return Date.now()
    },
  },
  methods: {
    clearFieldsForm() {
      this.userEmail = null
      this.userCountry = null
      this.listCountryUser = []

      this.$nextTick(() => {
        this.$refs.forgotPasswordForm.reset()
      })
    },
    async forgotPassword() {
      this.loadingSubmit = true

      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('email', this.userEmail)
          if (this.userCountry !== null) {
            formData.append('country', this.userCountry.value)
          }

          this.$store
            .dispatch('auth/forgotPassword', formData)
            .then(response => {
              if (Object.keys(response.configMobile).length > 1) {
                this.listCountryUser = []
                const listCountry = Object.keys(response.configMobile)
                listCountry.forEach(element => {
                  this.listCountryUser.push({
                    text: response.configMobile[element].countryApp,
                    value: response.configMobile[element].country,
                    icon: 'GlobeIcon',
                  })
                })
              } else if (Object.keys(response.configMobile).length === 1) {
                const responseAuth = response.configMobile[Object.keys(response.configMobile)[0]]
                this.clearFieldsForm()

                if ((responseAuth.error === 1) && (responseAuth.msg !== '')) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$t('Alerta'),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: responseAuth.msg,
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$t('Pedido efetuado com sucesso'),
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: responseAuth.msg,
                    },
                  })
                }
              }

              this.loadingSubmit = false
            })
            .catch(responseError => {
              if ((responseError.error === 1) && (responseError.msg !== '')) {
                this.clearFieldsForm()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$t('Pedido efetuado com sucesso'),
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: responseError.msg,
                  },
                })
              }
              this.loadingSubmit = false
            })
        } else {
          this.loadingSubmit = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
