var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('logo',{attrs:{"height":"35px","full":true}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":_vm.$t('Imagem')}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('Pedir nova password?'))+" ")]),_c('validation-observer',{ref:"forgotPasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-forgot-password-form mt-2",attrs:{"autocomplete":("form-auth-" + (Date.now()))},on:{"submit":function($event){$event.preventDefault();return _vm.forgotPassword($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":("user-email-" + _vm.formID)}},[_c('validation-provider',{attrs:{"name":_vm.$t('email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("user-email-" + _vm.formID),"name":("user-email-" + _vm.formID),"state":errors.length > 0 ? false:null,"placeholder":"","inputmode":"email","autocomplete":"off","tabindex":1,"readonly":_vm.emailReadOnly},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.listCountryUser.length)?_c('b-form-group',{attrs:{"label-for":("user-country-" + _vm.formID),"label":"País"}},[_c('validation-provider',{attrs:{"name":"país","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":("user-country-" + _vm.formID),"name":("user-country-" + _vm.formID),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listCountryUser,"state":errors.length > 0 ? false:null,"label":"text","tabindex":2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var text = ref.text;
var icon = ref.icon;
return [_c('feather-icon',{staticClass:"align-middle mr-50",attrs:{"icon":icon,"size":"16"}}),_c('span',[_vm._v(" "+_vm._s(text))])]}}],null,true),model:{value:(_vm.userCountry),callback:function ($$v) {_vm.userCountry=$$v},expression:"userCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loadingSubmit,"tabindex":3}},[(_vm.loadingSubmit===true)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.loadingSubmit===true)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Aguarde...'))+".")]):_vm._e(),(_vm.loadingSubmit===false)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Recuperar password')))]):_vm._e()],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Voltar'))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }